import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";
import FeedCard from "../Cards/FeedCard";
import FadeUpDown from "../Animation/FadeUpDown";

const Feedback = ({items}) => {
  return (
    <section className="px-[15%] py-20">
      <div>
        <FadeUpDown yOffset={15} delay={0.4}>
        <h1 className="text-center text-5xl font-semibold">
          Check what's our client
        </h1>
        <h1 className="text-primary py-3 text-center text-5xl font-semibold">
          say about us!
        </h1>
        </FadeUpDown>

        <Swiper className=""
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          loop={true}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          breakpoints={{
            850: {
                slidesPerView: 2
            }
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {
            items.map((item) => (
                <SwiperSlide className="py-10">
            <FeedCard {...item} />
          </SwiperSlide>
            ))
          }
          
        </Swiper>
      </div>
    </section>
  );
};

export default Feedback;


Feedback.defaultProps = {
    items: [
        {
            img: "/Assets/avatar.png",
            namee: "Mr Pratik",
            country: "Director, Viva Cosmetics",
            text: "TSS Software's ERP system has revolutionized our operations, delivering streamlined processes that have significantly boosted our productivity and efficiency. We're extremely satisfied with the results!"
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Rajan",
            country: "Modulinea",
            text: "TSS Software's solution has added tremendous value to our organization. It's an invaluable tool that has positively impacted our day-to-day operations and overall business performance."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Ms Savita",
            country: "Mahikapack",
            text: "Accurate data and robust reporting capabilities have become our new norm, thanks to TSS Software's ERP system. It has empowered us with valuable insights for better decision-making."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Babasaheb Hanvate",
            country: "Shree S. S. & Company",
            text: "TSS has streamlined our Inventory, Planning, Purchase, Finance, Sales, Operations and overall Quality. Very happy to get product features and functionality that best fit our requirements."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr CP",
            country: "CEO, Avalontec Singapore Pvt. Ltd.",
            text: "Our inventory management has seen a remarkable improvement since implementing TSS Software's ERP solution. It has brought a level of accuracy and control that we previously lacked."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Kamal",
            country: "Uvaam",
            text: "The system seamlessly handles our complex inventory, order processing, and production planning. It's a game-changer, optimizing our operations and elevating our customer service."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Manoj",
            country: "Umasurgicals",
            text: "The specialized features have significantly streamlined patient records management, appointment scheduling, and inventory tracking. The efficiency and accuracy are invaluable."
        },
        {
            img: "/Assets/avatar.png",
            namee: "Mr Sanket",
            country: "Sanket Packaging Machinery",
            text: "The ERP software by TSS has revolutionized how we manage our industrial machine packaging operations. It has markedly improved our productivity and ensured smooth packaging processes."
        },
              
        {
            img: "/Assets/avatar.png",
            namee: "Mr Irfan",
            country: "Aims Fragrance",
            text: "The system's ability to manage intricate fragrance formulas, production schedules, and supply chains is impressive. It has immensely improved our production efficiency."
        },
              
        
    ]
}