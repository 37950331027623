import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const What = () => {
    const List=[
        {
            title:'Cutting-Edge ERP Software',
            para:"We specialize in delivering state-of-the-art ERP software that streamlines operations, enhances data visibility, and facilitates smarter decision-making, ensuring your business stays ahead in today's fast-paced market."
        },
        {
            title:'Tailored Consultation & Strategy',
            para:'Our team of experts collaborates closely with you to understand your unique challenges and objectives. Providing tailored consultation, mapping out the most effective strategies to integrate within your existing workflow.',
        },
        {
            title:'Ongoing Support and Training',
            para:"Our commitment doesn't end with implementation. We provide continuous support and comprehensive training to empower your team to make the most of our ERP software, enabling sustainable growth and proficiency."
        },
    ]
  return (
    <div className='bg-secondary text-white'>
    <div className='md:px-[10%]  lg:px-[13%]  py-[12%] px-[10%]' style={{backgroundImage:`url('Assets/Group6.png')`,
    backgroundSize:'cover'}}>
        <FadeUpDown yOffset={15} delay={0.2}>
    <div className='grid pb-16 py-4 lg:grid-cols-10'>
        <div className='col-span-4'>
            <h1 className='md:text-5xl text-4xl font-semibold lg:pb-0 pb-5'>What we Do?</h1>
        </div>
        <div className='col-span-6'>
            <p className=''>As a leading provider of ERP solutions, we are committed to equipping businesses with the tools they need to succeed in the digital landscape.</p>
        </div>
    </div>
        </FadeUpDown>
        <FadeUpDown yOffset={15} delay={0.6}>
    <div className='grid sm:px-[30%] xs:px-[20%] md:px-0  gap-6 lg:grid-cols-3'>
        {
            List.map((Lists)=>(
                <div className='p-6 bg-primary flex flex-col gap-2 rounded-lg'>
                    <div className='w-12 rounded-lg h-12 bg-yellow'></div>
                    <h1 className='lg:text-[2vw] md:text-[2.5vw] sm:text-[3vw] xs:text-[4vw] text-[6vw] text-'>{Lists.title}</h1>
                    <p className=''>{Lists.para}</p>
                </div>
            ))
            
        }
    </div>
        </FadeUpDown>

    </div>
      
    </div>
  )
}

export default What