import React from "react";
import FadeUpDown from "../Animation/FadeUpDown";

const Important = () => {
  return (
    <div className="bg-secondary text-white">
      <div className="xl:px-[18%] md:px-[14%] lg:px-[10%] py-[8%]"
        style={{
          backgroundImage: `url('Assets/Group16.png')`,
          backgroundSize: "cover",
          backgroundPosition:'center'
        }}
      >
        <FadeUpDown yOffset={20} delay={0.2}>

        <div className="grid gap-8 lg:gap-4 lg:grid-cols-2">
          <div className="sm:pl-16 px-6 sm:px-5 md:pl-0">
            <h1 className="sm:text-5xl text-2xl pb-8 font-semibold">Important Stats</h1>
            <p className="2xl:w-[90%] sm:w-[90%] md:w-[90%] lg:w-[90%]">
            These numbers highlight not only our achievements but also the value we bring to our clients and partners.
            </p>
          </div>
          <div className="grid gap-6 lg:gap-4 px-[10%] md:px-0 sm:grid-cols-3">
            <div className="bg-primary py-10 rounded-lg gap-4 flex flex-col justify-center items-center">
                <h1 className="md:text-[2vw] text-xl font-semibold">2012</h1>
                <p className="font-thin text-sm">Founded Date</p>
            </div>
            <div className="bg-primary py-10 rounded-lg gap-4 flex flex-col justify-center items-center">
                <h1 className="md:text-[2vw] text-xl font-semibold">600+</h1>
                <p className="font-thin text-sm">Completed Projects</p>
            </div>
            <div className="bg-primary py-10 rounded-lg gap-4 flex flex-col justify-center items-center">
                <h1 className="md:text-[2vw] text-xl font-semibold">06</h1>
                <p className="font-thin text-sm">World Wide Branches</p>
            </div>
           
            
          </div>
        </div>
        </FadeUpDown>
      </div>
    </div>
  );
};

export default Important;