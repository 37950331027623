import React from 'react'

const Erpa = () => {
  return (
    <div>
    <div className='pt-14 lg:pt-0 lg:h-[70vh] h-[20vh] md:h-[30vh] flex items-center pl-[15%]' style={{backgroundImage:`url('Assets/Group13.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}>
    <h1 className='xl:text-5xl md:w-[80%] text-xl sm:w-[60%] xs:text-2xl sm:text-3xl md:text-4xl font-semibold text-white'>TSS Software with Excellence Services.</h1>

    </div>
      
    </div>
  )
}

export default Erpa