import React from 'react'
import { Link } from 'react-router-dom'

const Erp3 = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
}
  return (
    <div className="bg-secondary text-white">
    <div className="xl:px-[18%] px-[10%] max-w-[1600px] mx-auto md:px-[14%] lg:px-[10%] py-[4%]"
      style={{
        backgroundImage: `url('Assets/Group16.png')`,
        backgroundSize: "cover",
        backgroundPosition:'center'
      }}
    >
      <div className="grid gap-8 lg:gap-4 md:grid-cols-2">
        <div className="   sm:px-0 md:pl-0">
          <h1 className="lg:text-4xl md:text-2xl text-2xl  font-semibold">Leading ERP Company for Business Solution.</h1>
          
        </div>
        <div className=" flex items-center font-semibold md:justify-end">
          
        <Link to="/contact" onClick={scrollToTop}>
          <button className="text-secondary bg-white px-6 py-2 mb-4 lg:mb-0 rounded-3xl border-2 border-white hover:bg-primary hover:text-white font-medium">
            Request a Demo
          </button>
          </Link>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Erp3