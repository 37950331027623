import React from 'react'
import { Dynamic, Erpa, Important, Mission, Pilars } from '../Components'

const AboutUs = () => {
  return (
    <div>
        <Erpa />
        <Dynamic />
        <Important />
        <Mission />
        <Pilars />
    </div>
  )
}

export default AboutUs