import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const Product = () => {
    const List=[
        {
            title:'HRMS Cloud',
            color:'grad1/30'
        },
        {
            title:'Retail Pos Desktop',
            color:'grad2/20'
        },
        {
            title:'IMS Cloud',
            color:'grad1/30'
        },
        {
            title:'POS Cloud',
            color:'grad2/20'
        },
        {
            title:'PMS Cloud',
            color:'grad2/20'
        },
        {
            title:'Web Development',
            color:'grad1/30'
        },
        {
            title:'E-Commerce Development',
            color:'grad2/20'
        },
        {
            title:'Mobile Application Development',
            color:'grad1/30'
        },
        {
            title:'Digital Marketing',
            color:'grad1/30'
        },
        {
            title:'MLM Software Development',
            color:'grad2/20'
        },
        {
            title:'Blockchain Development',
            color:'grad1/30'
        },
        {
            title:'NETe Auto Calling System',
            color:'grad2/20'
        },
        {
            title:'NETe Vehicle Process System',
            color:'grad2/20'
        },
        {
            title:'NETe Digital Signage',
            color:'grad1/30'
        },
        {
            title:'NETe Home Automation',
            color:'grad2/20'
        },
        {
            title:'NETe Queue Management System',
            color:'grad1/30'
        },
    ]
  return (
    <div>
    <div className='xl:px-[10%] px-[10%] xs:px-[6%] pb-[10%]' style={{backgroundImage:`url('/Assets/Group32.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}>
        <FadeUpDown yOffset={15} delay={0.2}>
    <h1 className='xs:text-5xl text-2xl text-center py-[10%] font-semibold'>Our <span className='text-secondary'>Products</span></h1>
        </FadeUpDown>
        <FadeUpDown yOffset={15} delay={0.4}>
    <div className='grid gap-8 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4'>
    {
        List.map((Lists)=>(
            <div className={` bg-grad2/20  rounded-lg flex items-center text-lg font-semibold`}>
            <div className='h-12 w-12 m-4 rounded-lg bg-white'></div>
            <p className='w-[70%] p-2'>{Lists.title}</p>

            </div>
        ))
    }

    </div>
    </FadeUpDown>

    </div>
      
    </div>
  )
}

export default Product