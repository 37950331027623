import React from 'react'
import { Home, Swip, Offline, What, Landing, Feedback } from '../Components'

const HomePage = () => {
  return (
    <div>
        <Landing />
        <Home />
        <Swip />
        <Offline />
        <What />
        <Feedback />
    </div>
  )
}

export default HomePage