import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';

const Navbar = () => {

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="z-30 relative top-0">
      <div className={nav ? "bg-primary/[0.8] h-screen fixed z-30 w-screen ease-in-out duration-1000 pt-[20%] px-5 text-center" : "hidden"}>
      {links.map((link) => (
        <div className="py-7 border-b-[1px] border-white" onClick={handleNav}>
            <Link to={link.link} onClick={scrollToTop}>
              <button className="font-medium text-white text-xl">{link.text}</button>
            </Link>
        </div>
          ))}
          <div className="py-7" onClick={handleNav}>
          <Link to="/contact" onClick={scrollToTop}>
          <button className="text-primary w-full bg-white px-6 py-2 rounded-3xl border-2 border-white hover:bg-primary hover:text-white font-medium">
            Request a Demo
          </button>
          </Link>
          </div>
      </div>
    <div className="fixed w-full bg-white shadow-xl">
      <div className="px-[5%] py-4 flex justify-between items-center">
        <div>
          <Link to="/" onClick={scrollToTop}>
          <img src="/Assets/Logo.png" alt="" />
          </Link>
        </div>
        <div className="lg:flex justify-center gap-[7vw] hidden">
          {links.map((link) => (
            <Link to={link.link} onClick={scrollToTop}>
              <button className="font-medium hover:text-primary">{link.text}</button>
            </Link>
          ))}
        </div>
        <div className="lg:flex justify-center items-center hidden">
          <Link to="/contact" onClick={scrollToTop}>
          <button className="text-white bg-secondary px-6 py-1.5 rounded-3xl border-2 border-secondary hover:bg-white hover:text-secondary font-medium">
            Request a Demo
          </button>
          </Link>
        </div>
        <div className="lg:hidden block cursor-pointer" onClick={handleNav}>
        {
          !nav ? <FaBars /> : <ImCross />
        }
        </div>
        
      </div>
    </div>
        </section>
  );
};

export default Navbar;

const links = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Products",
    link: "/products",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
];
