import React from "react";
import { useState } from "react";
import { Accordion, AccordionBody } from "@material-tailwind/react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

const Question = ({ ques, ans, v, add }) => {
  const [arrow, setArrow] = useState(false);

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const handleArrow = () => {
    setArrow(!arrow);
  };

  return (
    <section className="py-2  md:pl-0">
      <Accordion
        open={open === Number(v)}
        onClick={() => handleOpen(Number(v))}
      >
        <div
          className="text-grey items-center flex gap-6 border-b-[0.1px] border-gray"
          onClick={handleArrow}
        >
          <div className="opacity-50 scale-90">
            {!arrow ? (
              <HiChevronDown className="w-10 h-10 cursor-pointer pt-1" />
            ) : (
              <HiChevronUp className="w-10 h-10 cursor-pointer" />
            )}
          </div>
          <h1 className="text-xl font-medium text-grey">{ques}</h1>
        </div>
        {arrow ? (
          <AccordionBody>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <iframe
                  src={ans}
                  title="Office"
                  width="300"
                  height="300"
                  style={{border: 0}}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div>
                <p className="md:py-10 text-base font-medium">{add}</p>
              </div>
            </div>
          </AccordionBody>
        ) : (
          <div></div>
        )}
      </Accordion>
      <div className="mb-3"></div>
    </section>
  );
};

export default Question;
