import React from "react";
import Question from "../Cards/Question";
import FadeUpDown from "../Animation/FadeUpDown";

const Office = ({items, global}) => {
  return (
    <section>
      <div className=" w-full py-16 lg:px-[20%] md:px-20 px-10">
        <FadeUpDown yOffset={15} delay={0.6}>
        <h1 className="text-2xl font-medium">
          Our Offices
        </h1>
        <h2 className="pt-5 opacity-70 pl-16 text-xl font-medium">Indian Offices</h2>
        </FadeUpDown>
        {items.map((item) => (
          <FadeUpDown yOffset={15} delay={0.6}>
          <Question {...item} />
          </FadeUpDown>
        ))}
        <FadeUpDown yOffset={15} delay={0.6}>
        <h2 className="pt-10 opacity-70 pl-16 text-xl font-medium">Global Presence</h2>
        </FadeUpDown>
        {global.map((glo) => (
          <FadeUpDown yOffset={15} delay={0.6}>
          <Question {...glo} />
          </FadeUpDown>
        ))}
      </div>
    </section>
  )
}

export default Office


Office.defaultProps = {
    items: [
      {
        v: "2",
        ques: "Mumbai Head Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.1498102369337!2d72.82123177474955!3d19.144918549806185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b61442fe43cb%3A0xe2896b18d8c58cda!2sLokhandwala%20Complex%20Market%2C%20Main%20Rd%2C%20Lokhandwala%20Complex%2C%20Andheri%20West%2C%20Mumbai%2C%20Maharashtra%20400053!5e0!3m2!1sen!2sin!4v1693867293287!5m2!1sen!2sin",
        add: 'Shop No. 3, RNA Arcade, Lokhandwala Complex, Andheri West, Mumbai-400053'
      },
      {
        v: "3",
        ques: "Mumbai Vasai Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.7005908966785!2d72.82692582475511!3d19.38211284229221!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7aec05f39f603%3A0x9d521eab25fe178!2sSukhmani%20Co-operative%20Housing%20Society!5e0!3m2!1sen!2sin!4v1693867407632!5m2!1sen!2sin",
        add: 'B/305, Sukhmani Apt., Near Gurudwara, Navghar, Vasai (W), Mumbai-401202'
      },
      {
        v: "4",
        ques: "Ahemdabad Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.0790594872838!2d72.6347944748508!3d23.020869216392583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e86606263e9a1%3A0x5385db8bc943f586!2sSumel%20-%207!5e0!3m2!1sen!2sin!4v1693867489657!5m2!1sen!2sin",
        add: 'C211, 2nd Floor, Sumel 7 Business Park, Sonini Chali, Narol - Naroda Rd Opposite Soma Textiles BRTS Bus Stop.. Rehmat Nagar, Bapunagar, Ahmedabad, Gujarat 382350'
      },
      {
        v: "5",
        ques: "Noida Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.933858424073!2d77.32514617503143!3d28.57174878683924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce55ff23f0093%3A0x51332df6cfd25f60!2sShree%20Ram%20Palace!5e0!3m2!1sen!2sin!4v1693867549385!5m2!1sen!2sin",
        add: 'A-5, 3rd Floor, Shree Ram Palace, Bhoga Market, Sector 27, Atta Noida, Delhi'
      },
      {
        v: "1",
        ques: "Bazpur Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111507.02911373584!2d78.96240158446484!3d29.14714387661643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390a6f62b36ab979%3A0x23dfcbae1fa52d7d!2sShiv%20Mandir!5e0!3m2!1sen!2sin!4v1693866833373!5m2!1sen!2sin",
        add: 'Shiv Mandir Market, Near Sugar Factory, Bazpur (Udham Singh Nagar) Uttarakhand - 262401'
      },      
    ],
    global: [
      {
        v:"6",
        ques: "Dubai Office",
        ans: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.6828183577!2d54.89782461447925!3d25.076280448840635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1694629709582!5m2!1sen!2sin"
      }
    ]
  };