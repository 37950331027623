import React from 'react'

const Privacy = () => {
    return (
        <div className="container" style={{ padding: '90px' }}>
            <h1><strong>Privacy Policy</strong></h1>
            <p><strong>TSS Software Pvt. Ltd.</strong> adheres to and implements a stringent privacy policy. Accordingly, we have developed this Policy for you to understand how we obtain, collect, use, communicate, disclose, and make use of personal information on our website <a href="https://www.tsssoftware.com" target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>www.tsssoftware.com</a>, and all its regional extensions and landing pages.</p>
            <ul>
                <li>Before or at the time of collecting personal information, we would identify the purposes for which information is being collected.</li>
                <li>We shall collect and use personal information solely to fulfill those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned, the data subject, or as required by law.</li>
                <li>We shall retain personal information only as long as required for the fulfillment of those purposes.</li>
                <li>We shall collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual necessary.</li>
                <li>Personal data shared and obtained should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up to date.</li>
                <li>We shall protect personal information through fair and reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use, or modification.</li>
                <li>We would make information about our policies and practices related to the management of personal information readily available to customers.</li>
                <li>We use third-party tools (including, but not limited to Google Analytics, etc.) to enhance user experience and the website’s functioning.</li>
            </ul>
        </div>
    )
}

export default Privacy