import React from "react";
import FadeUpDown from "../Animation/FadeUpDown";

const Mission = () => {
  return (
    <div className="2xl:px-[20%] pt-[8%] pb-[8%] lg:pb-0 px-6 xs:px-[10%] lg:pt-0 lg:px-[12%] xl:px-[15%]">
      <div className="grid gap-10 lg:gap-0 lg:grid-cols-2">
        <div className="  px-4 sm:pl-8 lg:py-[20%]">
          <FadeUpDown xOffset={15} delay={0.2}>
          <div className="xs:pl-4">
            <h1 className="lg:text-[2.5vw] pb-6 text-[6vw] sm:text-[3vw] font-semibold text-primary">
              Our Mission
            </h1>
            <p className="md:w-[90%]">
              TSS Software Pvt. Ltd. strives consistently to provide solutions
              and products using emerging technologies with high quality
              standards and zero errors. With a strong emphasis on research and
              development, TSS Software is constantly innovating with the
              development of new technologies and new products.
            </p>
          </div>
          </FadeUpDown>
        </div>
        <div className="  items-center flex justify-center ">
          <FadeUpDown xOffset={-15} delay={0.4}> 
          <img className="w-[70%] lg:w-full rounded-3xl" src="/Assets/Group17.png" alt="" />
          </FadeUpDown>
        </div>
      </div>
      <div className="grid gap-10 py-20 lg:py-0 lg:gap-0 lg:grid-cols-2">
        <div className="lg:order-1 order-2 items-center flex  justify-center ">
          <FadeUpDown xOffset={-15} delay={0.4}>
          <img className="w-[70%] lg:w-full rounded-3xl " src="/Assets/Group18.png" alt="" />
          </FadeUpDown>
        </div>
        <div className="lg:order-2 order-1 px-4 sm:pl-8 lg:py-[20%]">
          <FadeUpDown xOffset={15} delay={0.4}>
          <div className="xs:pl-4">
            <h1 className="lg:text-[2.5vw] text-[6vw] sm:text-[3vw] font-semibold text-primary ">
              Our Vision
            </h1>
            <p className="md:w-[90%]">
              To increase market reach via global expansions and to deliver the
              best business management application and integration expertise
              with faster return on investment to businesses world-wide.
            </p>
          </div>
          </FadeUpDown>
        </div>
      </div>
      <div className="grid gap-10 lg:gap-0 lg:grid-cols-2 ">
        <div className=" px-4 sm:pl-8 lg:py-[20%]">
          <FadeUpDown xOffset={15} delay={0.6}>
          <div className="xs:pl-4">
            <h1 className="lg:text-[2.5vw] pb-6 text-[6vw] sm:text-[3vw] font-semibold text-primary">
              Techincal Overview
            </h1>
            <p className="md:w-[90%]">
              Our methodologies and strong technical expertise have been proven
              across markets around the globe. Our Expertise in wide range of
              business applications in Multi-Tier distributed Architecture and
              Mobile device applications Provided Innovative and Quality IT
              Products and solutions to help you implement the agile, integrated
              systems and processes that will help your business continues to
              grow.
            </p>
          </div>
          </FadeUpDown>
        </div>
        <div className=" lg:order-2 order-1  items-center flex justify-center ">
          <FadeUpDown xOffset={-15} delay={0.6}>
          <img className="w-[70%] lg:w-full rounded-3xl" src="/Assets/Group19.png" alt="" />
          </FadeUpDown>
        </div>
      </div>
    </div>
  );
};

export default Mission;