import React from 'react'

const FeedCard = ({img, namee, country, text}) => {
  return (
    <div className='bg-sky/[0.7] px-10 py-5 rounded-xl my-5 w-full'>
        <div className='py-3'>
            <p className=' text-base'>{text}</p>
        </div>
        <div className='flex items-center gap-3 py-3'>
            <div className=''>
                <img className='h-14 w-14 rounded-full' src={img} alt='feedback' />
            </div>
            <div className=''>
                <h2 className='text-xl font-semibold'>{namee}</h2>
                <h3 className='text-base '>{country}</h3>
            </div>
        </div>
        
    </div>
  )
}

export default FeedCard