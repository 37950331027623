import React from 'react'
import { Erp, Erp3, Form, Office } from '../Components'

const ContactUs = () => {
  return (
    <div>
        <Erp />
        <Form />
        <Erp3 />
        <Office />
    </div>
  )
}

export default ContactUs