import React from 'react'
import { Collage, Erp2, Erp3, Modules, Product } from '../Components'

const Products = () => {
  return (
    <div>
      <Erp2 />
      <Modules />
      <Collage />
      <Product />
      <Erp3 />
    </div>
  )
}

export default Products