import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import FadeUpDown from "../Animation/FadeUpDown";

const Swip = () => {
  const List = [
    {
      title: "Orthopedics",
    },
    {
      title: "Shipping",
    },
    {
      title: "Pharma",
    },
    {
      title: "Jewellery",
    },
    {
      title: "Cosmetics",
    },
    {
      title: "Steel",
    },
    {
      title: "Garments",
    },
    {
      title: "Recycling",
    },
    {
      title: "Textiles",
    },
    {
      title: "Automobiles",
    },
    {
      title: "Plastic",
    },
    {
      title: "Resort",
    },
    {
      title: "Food",
    },
    {
      title: "Engineering",
    },
    {
      title: "Foil",
    },
    {
      title: "Gas",
    },
    {
      title: "Kitchen",
    },
    {
      title: "Footwear",
    },
    {
      title: "Sugar",
    },
    {
      title: "Parking",
    },
    {
      title: "Restaurant",
    },
    {
      title: "Orthopedics",
    },
    {
      title: "Shipping",
    },
    {
      title: "Pharma",
    },
    {
      title: "Jewellery",
    },
    {
      title: "Cosmetics",
    },
    {
      title: "Steel",
    },
    {
      title: "Garments",
    },
    {
      title: "Recycling",
    },
    {
      title: "Textiles",
    },
    {
      title: "Automobiles",
    },
    {
      title: "Plastic",
    },
    {
      title: "Resort",
    },
    {
      title: "Food",
    },
    {
      title: "Engineering",
    },
    {
      title: "Foil",
    },
    {
      title: "Gas",
    },
    {
      title: "Kitchen",
    },
    {
      title: "Footwear",
    },
    {
      title: "Sugar",
    },
    {
      title: "Parking",
    },
    {
      title: "Restaurant",
    },
  ];
  return (
    <div className="bg-blue w-full text-white">
      <div
        style={{
          backgroundImage: `url('Assets/Group11.png')`,
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col lg:py-16 py-8 items-center">
          <FadeUpDown yOffset={15} delay={0.2}>
          <h1 className="text-4xl text-center font-semibold lg:pb-12 pb-5">
            Industry Specific ERP Solutions
          </h1>
          </FadeUpDown>
          <FadeUpDown yOffset={15} delay={0.4}>
          <p className="text-center lg:px-[10%] px-5">
            Unlock the true potential of your industry with our specialized ERP
            software. Our industry-specific solutions are meticulously crafted
            to address the unique challenges and opportunities that your
            business faces. Seamlessly integrate processes, optimize workflows,
            and gain a competitive edge by harnessing the power of ERP designed
            exclusively for your sector.
          </p>
          </FadeUpDown>
          <div className="  w-full lg:pt-24 pt-10 sample-slider">
            <Swiper
              loop={true}
              speed={5000}
              slidesPerView={6}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}true
              spaceBetween={40}
              // freeMode={true}

              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                475: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                646: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                950: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 6,
                  spaceBetween: 30,
                },
              }}
              modules={[Autoplay]}
              className="h-[12vh]    "
            >
              {List.map((Lists) => (
                <SwiperSlide>
                  <div className="w-full bg-primary rounded-xl  p-2 gap-8 grid grid-cols-5 ">
                    <div className="w-8 col-span-1 h-8 bg-yellow rounded-md"></div>
                    <div className="flex  items-center">
                      <h1 className="col-span-4">{Lists.title}</h1>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Swiper
              loop={true}
              speed={5000}
              slidesPerView={5}
              autoplay={{
                delay: 0,
                reverseDirection: true,
                disableOnInteraction: false,
              }}
              grabCursor={true}
              spaceBetween={50}
              // freeMode={true}

              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                475: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                646: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
                950: {
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1280: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[Autoplay]}
              className="h-[12vh]   "
            >
              {List.map((Lists) => (
                <SwiperSlide>
                  <div className="w-full bg-primary rounded-xl  p-2 gap-8 grid grid-cols-5 ">
                    <div className="w-8 col-span-1 h-8 bg-lightBlue rounded-md"></div>
                    <div className="flex  items-center">
                      <h1 className="col-span-4">{Lists.title}</h1>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Swip;
