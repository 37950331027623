import React from 'react'
import FadeUpDown from '../Animation/FadeUpDown'

const data = [
  {
    img: '/Assets/p1.png',
  },
  {
    img: '/Assets/p2.png',
  },
  {
    img: '/Assets/p3.png',
  },
  {
    img: '/Assets/p4.png',
  },
  {
    img: '/Assets/p5.png',
  },
  {
    img: '/Assets/p6.png',
  },
  {
    img: '/Assets/p7.png',
  },
  {
    img: '/Assets/p8.png',
  },
  {
    img: '/Assets/p9.png',
  },
  {
    img: '/Assets/p10.png',
  },
  {
    img: '/Assets/p11.png',
  },
  {
    img: '/Assets/p12.png',
  },
  {
    img: '/Assets/p13.png',
  },
  {
    img: '/Assets/p14.png',
  },
  {
    img: '/Assets/p15.png',
  },
  {
    img: '/Assets/p16.png',
  },
  {
    img: '/Assets/p17.png',
  },
  {
    img: '/Assets/p18.png',
  },
  {
    img: '/Assets/p19.png',
  },
  {
    img: '/Assets/p20.png',
  },
  {
    img: '/Assets/p21.png',
  },
  {
    img: '/Assets/p22.png',
  },
  {
    img: '/Assets/p23.png',
  },
  {
    img: '/Assets/p24.png',
  },
  {
    img: '/Assets/p25.png',
  },
  {
    img: '/Assets/p26.png',
  },
  {
    img: '/Assets/p27.png',
  },
  {
    img: '/Assets/p28.png',
  },
  {
    img: '/Assets/p29.png',
  },
  {
    img: '/Assets/p30.png',
  },
]

const Collage = () => {
  return (
    <section className='bg-blue'>
      <div className='' style={{backgroundImage: `url('/assets/bgGrp.png')`, backgroundSize:'cover'}}>
          <FadeUpDown yOffset={15} delay={0.4}>
          <div className='py-20 text-white'>
          <h1 className="text-4xl text-center font-semibold lg:pb-12 pb-5">
            Industry Specific ERP Solutions
          </h1>
          <p className="text-center lg:px-[10%] px-5">
            Unlock the true potential of your industry with our specialized ERP
            software. Our industry-specific solutions are meticulously crafted
            to address the unique challenges and opportunities that your
            business faces. Seamlessly integrate processes, optimize workflows,
            and gain a competitive edge by harnessing the power of ERP designed
            exclusively for your sector.
          </p>
          </div>
          </FadeUpDown>
          <div className='grid md:grid-cols-3 lg:mx-[20%] mx-[10%] pb-20'>
              {
                data.map((d) => (
                  <div>
                    <FadeUpDown yOffset={15} delay={0.2}>
                    <img className='scale-105 hover:scale-110' src={d.img} alt='product' />
                    </FadeUpDown>
                  </div>
                ))
              }
          </div>
      </div>
    </section>
  )
}

export default Collage