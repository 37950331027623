import React from "react";
import FadeUpDown from "../Animation/FadeUpDown";

const Home = () => {

  return (
    <div>
      <div>
        {/* <img className='w-full' src='/Assets/Group1.png' alt="" /> */}
      </div>
      <div className="xl:px-[17%] px-[10%] lg:py-24 py-10">
        <div className="grid gap-6 lg:grid-cols-2">
          <div className=" flex flex-col gap-4">
            <FadeUpDown xOffset={-15} delay={0.4}>
              <p className=" text-red text-red-500">ABOUT US</p>
            </FadeUpDown>
            <FadeUpDown xOffset={-15} delay={0.6}>
              <p className="lg:text-[3vw] xs:text-[5.5vw] font-semibold leading-tight">
                Leading ERP Company for{" "}
                <span className="text-primary">Business Solution.</span>
              </p>
            </FadeUpDown>
            <FadeUpDown xOffset={-15} delay={0.8}>
              <p className="pt-2 ">
                TSS Software Offer ERP Solutions Designed to Meet the operational need of SMEs globally.
              </p>

              <p className="pt-4 pb-4 ">
                The TSS Software ERP Solution helps client take well-informed and balanced decision based on real –time data. It empowers employees to stay connected and work efficiently and also enhances customer engagement with real-time feedback and end-user application.
              </p>
            </FadeUpDown>
            <FadeUpDown xOffset={-15} delay={1}>
              <a
                href="/Assets/TSSSoftware.pdf"
                download="TSS Software Brochure"
                target="_blank"
                rel="noreferrer">
                <button className="text-white bg-secondary px-6 py-1.5 mb-4 lg:mb-0 rounded-3xl border-2 border-secondary hover:bg-white hover:text-secondary font-medium">
                  Download Brochure
                </button>
              </a>
            </FadeUpDown>
          </div>
          <div className="flex items-center justify-center lg:mt-7">
            <FadeUpDown xOffset={15} delay={0.4}>
              <img className="w-[90%]" src="/Assets/Group4.png" alt="" />
            </FadeUpDown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
