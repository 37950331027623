import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
// import { Link } from "react-router-dom";

import "swiper/css";

const Landing = () => {
  const data = [
    {
      Tagline: "Empowering Businesses with Seamless Solutions",
      Main: "Transforming Operations with Advanced ERP Solutions",
      Subtext:
        "Specialized in delivering cutting-edge ERP software solutions tailored to your business needs.",
      Subtext2:
        "Specialized in delivering cutting-edge ERP software solutions tailored to your business needs. With our expertise, we enable businesses to streamline processes, enhance productivity, and achieve growth like never before.",
      button: "Get a Demo",
      link: "/contact",
      img: "/Assets/land1.png",
    },
    {
      Tagline: "Our Journey and Expertise",
      Main: "Discover Our Story and Commitment",
      Subtext:
        "We're more than just a software company – we're your partners in success. ",
      Subtext2:
        "We're more than just a software company – we're your partners in success. Founded 2012, we have been dedicated to revolutionizing how businesses operate by offering top-tier ERP solutions.",

      button: "Know More",
      link: "/about",
      img: "/Assets/land2.png",
    },
    {
      Tagline: "Exploring Our Comprehensive Product Suite",
      Main: "Unleash the Power of Our ERP Products",
      Subtext:
        "Step into the world of possibilities with our diverse range of ERP products. ",
      Subtext2:
        "Step into the world of possibilities with our diverse range of ERP products. From inventory management to financial analytics, our solutions are designed to cater to every aspect of your business.",
      button: "Explore Products",
      link: "/products",
      img: "/Assets/land3.png",
    },
    {
      Tagline: "Witness the Future of Your Business",
      Main: "Experience Our ERP Solutions Firsthand",
      Subtext:
        "Ready to take the next step toward transforming your business? Request a demo of our ERP software ",
      Subtext2:
        "Ready to take the next step toward transforming your business? Request a demo of our ERP software and see how it can revolutionize your operations. Get in touch today to schedule your personalized demo.",

      button: "Get in Touch",
      link: "/contact",
      img: "/Assets/land4.png",
    },
  ];

  return (
    <section className="">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        //   pagination={{ clickable: true }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {data.map((d) => (
          <SwiperSlide>
            <div className="relative">
              {/* <div className="absolute lg:pt-[10%] md:pt-[15%] pt-[20%] w-full">
                <div className="w-fit mx-auto">
                  <h3 className="md:inline hidden bg-secondary/[0.6] px-5 py-1 rounded-3xl font-mono font-medium lg:text-base md:text-sm text-xs">
                    {d.Tagline}
                  </h3>
                </div>
                <div className="w-fit mx-auto lg:py-10 md:py-5 py-2 text-secondary font-semibold lg:px-[20%] md:px-[10%] px-[15%]">
                  <h1 className="lg:text-6xl text-xl md:text-4xl text-center lg:leading-snug">
                    {d.Main}
                  </h1>
                </div>
                <div className="w-fit mx-auto lg:py-5 font-semibold lg:px-[15%] md:px-[4%] px-3 lg:hidden">
                  <p className="text-center leading-snug lg:text-xl md:text-base text-xs">
                    {d.Subtext}
                  </p>
                </div>
                <div className="w-fit mx-auto pb-5 font-semibold lg:px-[15%] md:px-[4%] px-3 lg:block hidden">
                  <p className="text-center leading-snug lg:text-xl md:text-base text-xs">
                    {d.Subtext2}
                  </p>
                </div>
                <div className="grid place-content-center md:my-3 my-2 lg:my-5">
                  <Link to={d.link}>
                    <button className="text-white bg-secondary md:px-6 px-3 md:py-1.5 rounded-3xl border-2 border-secondary hover:bg-white hover:text-secondary font-medium">
                      {d.button}
                    </button>
                  </Link>
                </div>
              </div> */}
              <img className="lg:w-full pt-[20%] md:pt-0" src={d.img} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Landing;
