import React from 'react'

const Erp2 = () => {
  return (
    <div>
    <div className='pt-14 lg:h-[70vh] h-[30vh] flex items-center pl-[15%]' style={{backgroundImage:`url('Assets/Group21.png')`,
    backgroundSize:'cover',
    backgroundPosition:'center'}}>
    <h1 className='xl:text-5xl w-[80%] text-xl sm:w-[60%] xs:text-2xl sm:text-3xl md:text-4xl font-semibold text-white'>We’ve been providing our software services with since 2012.</h1>

    </div>
      
    </div>
  )
}

export default Erp2